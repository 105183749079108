<template>
    <div>
        <v-container fluid>
            <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
                <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
                <v-btn dark text @click="snackbar = false">Close</v-btn>
            </v-snackbar>
            <div style="background: white;">
                <div class="pageHeight" style="width: auto;margin: 10px;padding: 8px;border: 5px solid #787878;">
                    <v-card style="background:aliceblue;">
                        <v-card-text>
                            <div style="position: absolute;left: 0">
                                <img style="height:90px;width:150px" :src="logo" />
                            </div>
                            <div style="text-align: center; color: blue">
                                <div cols="12" sm="10" style="color:blue;">
                                    <center>
                                        <div v-if="trust_name != ' '" class="text-center">{{ trust_name }} 's</div>
                                        <div class="text-center" style="font-size:18px;">
                                            <b>{{ org_detailed_name }}</b>
                                        </div>
                                        <div class="text-center" style="font-size:14px;">
                                            <b>{{ address }}</b>
                                        </div>
                                    </center>
                                </div>
                            </div>
                            <br>
                            <p style="font-weight: bold; font-size:24px;color: darkorange;">
                                <center>Admission (AY : {{ay}})</center> 
                            </p>
                            <hr style="margin-top: 0px;margin-bottom: 0px;">
                            <span style="text-align: justify; font-weight: 500; color:red;">
                                <span style="font-weight: bold;">*</span><span style="color:black;"> Fields are madatory</span>
                                <br /><br />
                            </span>
                            <v-row dense>
                                <v-col cols="12" sm="3">
                                    <v-text-field 
                                        v-model="applicationno" 
                                        placeholder="Enter Application Number" 
                                        label="Application Number *"
                                        required="true"
                                        dense
                                        outlined 
                                        persistent-hint 
                                        @change="fetchdata=false"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="1">
                                    <v-btn color="primary" dark class="mb-2" @click="proceed()">Fetch</v-btn>
                                </v-col>
                            </v-row>
                            <v-alert v-if="error" type="error">
                                {{ error }}
                            </v-alert>
                            <div v-else-if="fetchdata">
                                <v-row dense>
                                    <v-col cols="12" sm="4">
                                        <label><strong style="font-family: Tahoma; font-size: 16px">First Name</strong></label>
                                        {{ provisionadmission.firstName }}
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <label><strong style="font-family: Tahoma; font-size: 16px">Middle Name</strong></label>
                                        {{ provisionadmission.middleName }}
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <label><strong style="font-family: Tahoma; font-size: 16px">Last Name</strong></label>
                                        {{ provisionadmission.lastName }}
                                    </v-col>
                                </v-row>
                                <br>
                                <v-row dense>
                                    <v-col cols="12" sm="4">
                                        <label><strong style="font-family: Tahoma; font-size: 16px">Program Type : </strong></label>
                                        {{ provisionadmission.programtype }}
                                    </v-col>
                                    <v-col cols="12" sm="8">
                                        <label><strong style="font-family: Tahoma; font-size: 16px">Program</strong></label>
                                        {{ provisionadmission.program }}
                                    </v-col>
                                </v-row>
                                <br>
                                <v-row dense>
                                    <v-col cols="12" sm="4">
                                        <label><strong style="font-family: Tahoma; font-size: 16px">Email</strong></label>
                                        {{ provisionadmission.email }}
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <label><strong style="font-family: Tahoma; font-size: 16px">Mobile No.</strong></label>
                                        {{ provisionadmission.mobileno }}
                                    </v-col>
                                </v-row>
                                <br>
                                <v-row dense>
                                    <v-col cols="12" sm="3"></v-col>
                                    <v-col cols="12" sm="6">
                                        <v-simple-table style="border: 1px solid;width:90%;">
                                            <template v-slot:default>
                                                <thead>
                                                    <tr style="background:orange;font-weight : 600;">
                                                        <td class="text-left">Sr.No.</td>
                                                        <td class="text-left">Particulars of the Fees</td>
                                                        <td class="text-left">Amount</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, index) in provisionadmission.component_list" :key="index">
                                                        <td>{{ index+1 }}</td>
                                                        <td>{{ item.component }}</td>
                                                        <td>{{ item.amount }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2">Amount to be paid for Provisional Admission</td>
                                                        <td colspan="2">{{provisionadmission.feesamount }}</td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-col>
                                    <v-col cols="12" sm="3"></v-col>
                                </v-row>
                                <br />
                                <v-row>
                                    <v-col cols="12" sm="12">
                                        <v-alert type="warning" prominent style="color:black;">
                                            <v-checkbox
                                            v-model="checkboxterm"
                                            color="red"
                                            checked="false"
                                            :label="'I declare that the information provided above is true, complete and correct. I am proceding to pay for Admission At PCU ('+ay+')'"
                                        ></v-checkbox>
                                        </v-alert>
                                        <br>
                                        <v-alert type="alert" prominent v-if="provisionadmission">
                                            If your transaction is completed (Money Deducted from your Account) and Receipt is not generated Please contact Admission Section. Do not try to Pay fees again.
                                            <br />Please note down your Reference number is
                                            <span style="font-size:18px; font-weight:700;">{{mrctTxtID}}.</span>
                                        </v-alert>
    
                                        <span style="text-align: justify; font-weight: 500; color:red;">
                                            1. You are suggested to use Nationalized Bank Credentials (Debit / Credit Card / UPI ) to make payment of fees.<br/>
                                            2. The payment receipt will be generated / emailed to you within 24 hours after the successful payment transaction.<br/>
                                            3. In case of payment failure/error, kindly contact VU admission office. We will let you know the status and will arrange for payment receipt (If any). 
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="12" align="center">
                                        <v-btn v-if="checkboxterm && billdesk" color="primary" dark class="mb-2" @click="payFees()">
                                            <v-icon>mdi-content-save</v-icon>Pay Fees
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <br /><br />
                            </div>
                        </v-card-text>
                    </v-card>
                </div>
                <br>
            </div>
        </v-container>
    </div>
</template>
<script>
import axios from "axios";
const CryptoJS = require("crypto-js")
export default {
    data() {
        return {
            error: "",
            snackbar_msg: "",
            color: "",
            snackbar: false,
            applicationno : null,
            fetchdata : false,
            ay : null,
            ayid : null,
            provisionadmission : [],
            checkboxterm : false,
            payable_amount: 0,
            checksumurl : null,
            billdesk:false,
            mrctTxtID : null,
            logo : null,
            trust_name : null,
            org_detailed_name : null,
            address : null,
        };
    },
    mounted() {
        this.onLoad();
    },
    methods: {
        onLoad() {
            axios
                .post("/AppLearnerAdmission/getCurrentAyPCU")
                .then((res) => {
                    if (res.data.status == "200") {
                        this.ay = res.data.ay;
                        this.ayid = res.data.ayid;
                        this.logo = res.data.logo;
                        this.trust_name = res.data.trust_name;
                        this.org_detailed_name = res.data.org_detailed_name;
                        this.address = res.data.address;
                    } else if (res.data.status == "401") {
                        this.error = res.data.msg;
                    } else {
                        this.showSnackbar("#b71c1c", res.data.msg);
                    }
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something Went Wrong...");
                })
                .finally(() => {
                // 'use strict';
                });
        },

        proceed() {
            if (!this.applicationno || this.applicationno == null || this.applicationno == "") {
                this.showSnackbar("#b71c1c", "Please Enter Application Number !");
            }else{
                const data = {
                    applicationno: this.applicationno,
                    ayid : this.ayid,
                };
                axios
                .post("/AppLearnerAdmission/pcuadmissionapplication", data)
                .then((res) => {
                    if (res.data.status == "200") {
                        this.provisionadmission = res.data.provisionadmission;
                        this.mrctTxtID = res.data.mrctTxtID;
                        this.payable_amount = res.data.payable_amount;
                        this.checksumurl = res.data.checksumurl;
                        this.billdesk = res.data.billdesk;
                        this.fetchdata = true;
                        this.error=  "";
                    }else if (res.data.status == "201") {
                        let routeData = this.$router.push({
                            name: "pcu-admission-receipt",
                            query: { 
                                rec_no: this.encrypt(res.data.receipt),
                            }
                        });
                        window.open(routeData.href, '_self');
                    }else if (res.data.status == "401") {
                        this.error = res.data.msg;
                    } else {
                        this.showSnackbar("#b71c1c", res.data.msg);
                    }
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something Went Wrong...");
                })
                .finally(() => {
                // 'use strict';
                });
            }
        },

        payFees() {
            bdPayment.initialize  ({
                msg: this.checksumurl,
                options: {
                    enableChildWindowPosting: true,
                    enablePaymentRetry: true,
                    retry_attempt_count: 2
                }
            });
        },

        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },

        encrypt (src) {
            const passphrase = '987654'
            var data =  CryptoJS.AES.encrypt(src, passphrase).toString();
            return data
        },
    },
};
</script>
<style>
    .pageHeight {
          height: auto;
    }
</style>
  
  